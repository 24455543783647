<template>
    <section class="content">
        <div class="overlay-wrapper">
            <form role="form" @submit.prevent="submitForm">
            <div class="card">
                <div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 form-group">
                    <label class="control-label">TANGGAL</label>
                    <datepicker v-model="form.tanggal" :options="{ orientation: 'bottom', format: 'dd/mm/yyyy', autoclose: true }" id="tanggal" />
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">KURIR</label>
                        <select required v-model="form.courier_id" id="courier_id" name="courier_id" class="form-control">
                            <option v-for="courier in totalCourier" v-bind:key="courier.courier_id" v-bind:value="courier.courier_id">
                                {{ courier.label }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">REKENING</label>
                        <v-select :options="totalBank" v-model="form.rekening_id" :reduce="opt => opt.rekening_id" />
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">SCENARIO</label>
                        <select required v-model="form.scenario_id" id="scenario_id" name="scenario_id" class="form-control">
                            <option v-for="row in totalScenario" v-bind:key="row.id" v-bind:value="row.id">
                                {{ row.label }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                    <label class="control-label">UPLOAD DATA AWB</label>
                    <button class="btn btn-secondary mb-3 float-right" @click.prevent="downloadTemplate">
                        <i class="fa fa-download"></i> Unduh Template
                        <span v-if="loadingDownload" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    </div>
                    <div class="col-md-12">
                        <form action="/target" ref="dzone" class="dropzone card" style="border-style: dashed;">
                        <div class="overlay" v-if="loading">
                        <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        </div>
                        <div class="dz-message text-muted" data-dz-message><h4><div><i class="fa fa-upload"></i></div><span>Upload file Anda disini</span></h4></div>
                        </form>
                        <span class="text-muted" v-if="!msg ? false : true">{{msg}}</span>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" :disabled="(!form.rekening_id || !form.courier_id || !form.scenario_id || !msg || loading2)" class="btn btn-primary" id="submit">
                    Simpan
                <span v-if="loading2" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
            </div>
            </form>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import Dropzone from "dropzone";
import XLSX from "xlsx";
import Swal from 'sweetalert2';
import $ from 'jquery';
import "dropzone/dist/dropzone.css";
import datepicker from "@/components/Datepicker";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: 'FormRekonShipper',
    data() {
        return {
            errors: [],
            totalScenario: [
                {label: 'Jurnal BOSCOD membayar ongkir (13)', id: 13}, 
                {label: 'Jurnal BOSCOD membayar COD fee (14)', id: 14},
            ],
            roles: '',
            msg: '',
            loading: false,
            loading2: false,
            loadingDownload: false,
            data: [],
            formTitle: 'Tambah',
            form: {
                tanggal: moment().format("DD/MM/YYYY"),
                courier_id: '',
                rekening_id: '',
                scenario_id: '',
            },
            formData: [],
            totalCourier: [],
            totalBank: [],
            disabled: true,
            file: {},
            loadingContent: '',
        }
    },
    components: {
        datepicker,
        vSelect,
    },
    computed: {},
    created: function() {
        authFetch("/shipper/kurir")
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            this.totalCourier = js.data;
        });

        this.loadBank();
        this.form.tanggal = moment().format("DD/MM/YYYY");
    },
    methods: {
        loadBank: function () {
        authFetch("/payment/saldo/bank")
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                this.totalBank = js.data;
            });
        },
        btnFile() {
            var input = document.querySelector("#excelFile");
            input.click();
        },
        downloadTemplate: function()
        {
            this.loadingDownload = true;
            authFetch("/file/aset/template_kurir", {
                method: 'GET',
            })
            .then(response => response.blob())
            .then(blob => {
                this.loadingDownload = false;
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "template.xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again         
            });

        },
        submitForm: function (ev) {
        var self = this;
        const e = this.$refs;

        var allData = { group: self.form, items: self.formData };
        var urlSubmit = "/report/hutang/kurirnew";

        var formData = new FormData();
            formData.append('userfile', this.file);
            formData.append('rekening_id', this.form.rekening_id);
            formData.append('courier_id', this.form.courier_id);
            formData.append('tanggal', this.form.tanggal);
            formData.append('scenario_id', this.form.scenario_id);

        this.loading2 = true;
        Swal.fire({
        title: "Simpan?",
        showCancelButton: true,
        confirmButtonText: `Ya`,
        cancelButtonText: "Tidak",
        }).then((result) => {
        this.loadingContent = true;
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
        authFetch(urlSubmit, {
            method: "POST",
            body: formData,
            headers: {
            'Content-Type': null,
            },
        })
        .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            } else if (res.status === 500){
                Swal.fire("Timeout!", ``, "error");
                this.loadingContent = false;
                this.loading2 = false;
                this.msg = '';
            }
            return res.json();
        })
        .then((js) => {
            this.loadingContent = false;
            this.loading2 = false;
            if (js.success) {
                Swal.fire("Proses Berhasil", ``, "success");
                this.form = {};
                this.msg = '';
            } else {
                Swal.fire("Proses Gagal", `Data AWB kemungkinan sudah pernah diupload / tidak valid.`, "error");
            }
        });
        }
        });

        ev.preventDefault();
        },
    },
    mounted() {
        const e = this.$refs;

        //upload excel
        Dropzone.autoDiscover = false;
        let myDropzone = new Dropzone(this.$refs.dzone, {
            uploadMultiple: false, maxFiles: 1,
            createImageThumbnails: false,autoProcessQueue: false,
            url: 'getMeSomeUrl',
            accept: function(file, done) {
                console.log('accept', file, done)
                this.removeFile(file);
            },
            success: function(file)
            {
                this.removeFile(file);
            }
        });
        myDropzone.on("addedfile", file => {
            this.loading = true;
            this.file = file;
            setTimeout(() => {
                this.loading = false;
                this.msg = file.name;
            }, 2000);
            console.log(`File added: ${file.name}`);
            console.log(file);
        });
        myDropzone.on("maxfilesexceeded", function (file) {
            myDropzone.removeAllFiles();
        });
    }
}
</script>
<style scoped>